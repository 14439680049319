import { getAllCommonIngredients, getAllCuisines, getAllEquipments, getAllFeelings, getAllMeals } from 'api/misc';
import { getAll } from 'api/occasion';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';

function DataForm(props) {
    const [cuisineData, setCuisineData] = useState({ data: [], isLoading: false });
    const [commonIngredientsData, setCommonIngredientsData] = useState({ data: [], isLoading: false });
    const [equipmentData, setEquipmentData] = useState({ data: [], isLoading: false });
    const [mealsData, setMealsData] = useState({ data: [], isLoading: false });
    const [occasionData, setOccasionData] = useState({ data: [], isLoading: false });
    const [feelingData, setFeelingData] = useState({ data: [], isLoading: false });

    const selectedCuisine = useMemo(() => {
        return props.fields?.cuisines.map((cuisine) => cuisine.id);
    }, [props.fields?.cuisines]);

    const selectedCommonIngredients = useMemo(() => {
        return props.fields?.common_ingredients.map((item) => item.id);
    }, [props.fields?.common_ingredients]);

    const selectedEquiments = useMemo(() => {
        return props.fields?.equipment.map((item) => item.id);
    }, [props.fields?.equipment]);

    const selectedFeelings = useMemo(() => {
        return props.fields?.feelings.map((item) => item.id);
    }, [props.fields?.feelings]);

    const selectedMeals = useMemo(() => {
        return props.fields?.meals.map((item) => item.id);
    }, [props.fields?.meals]);

    const selectedOccasions = useMemo(() => {
        return props.fields?.occasions.map((item) => item.id);
    }, [props.fields?.occasions]);

    useEffect(() => {
        fetchCuisines();
        fetchFeelings();
        fetchMeals();
        fetchOccasions();
        fetchCommonIngredients();
        fetchEquipments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCuisines = useCallback(async () => {
        setCuisineData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAllCuisines();

        if (response) {
            setCuisineData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const fetchCommonIngredients = useCallback(async () => {
        setCommonIngredientsData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAllCommonIngredients();

        if (response) {
            setCommonIngredientsData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const fetchEquipments = useCallback(async () => {
        setEquipmentData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAllEquipments();

        if (response) {
            setEquipmentData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const fetchMeals = useCallback(async () => {
        setMealsData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAllMeals();

        if (response) {
            setMealsData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const fetchOccasions = useCallback(async () => {
        setOccasionData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAll();

        if (response) {
            setOccasionData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const fetchFeelings = useCallback(async () => {
        setFeelingData((prevValue) => ({ ...prevValue, isLoading: true }));

        const response = await getAllFeelings();

        if (response) {
            setFeelingData({
                data: [...response],
                isLoading: false
            });
        }
    }, []);

    const onChangeCuisines = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedCuisine.includes(item.id)) {
            const idx = selectedCuisine.indexOf(item.id);
            const arr = props.fields.cuisines;

            arr.splice(idx, 1);
            props.setValue('cuisines', [...arr]);
        } else if (selectedCuisine.length < 3) {
            const arr = props.fields.cuisines;

            arr.push(item);
            props.setValue('cuisines', [...arr]);
        }
    }, [props, selectedCuisine]);

    const onChangeCommonIngredients = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedCommonIngredients.includes(item.id)) {
            const idx = selectedCommonIngredients.indexOf(item.id);
            const arr = props.fields.common_ingredients;

            arr.splice(idx, 1);
            props.setValue('common_ingredients', [...arr]);
        } else if (selectedCommonIngredients.length < 3) {
            const arr = props.fields.common_ingredients;

            arr.push(item);
            props.setValue('common_ingredients', [...arr]);
        }
    }, [props, selectedCommonIngredients]);

    const onChangeEqupments = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedEquiments.includes(item.id)) {
            const idx = selectedEquiments.indexOf(item.id);
            const arr = props.fields.equipment;

            arr.splice(idx, 1);
            props.setValue('equipment', [...arr]);
        } else if (selectedEquiments.length < 3) {
            const arr = props.fields.equipment;

            arr.push(item);
            props.setValue('equipment', [...arr]);
        }
    }, [props, selectedEquiments]);

    const onChangeMeals = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedMeals.includes(item.id)) {
            const idx = selectedMeals.indexOf(item.id);
            const arr = props.fields.meals;

            arr.splice(idx, 1);
            props.setValue('meals', [...arr]);
        } else if (selectedMeals.length < 3) {
            const arr = props.fields.meals;

            arr.push(item);
            props.setValue('meals', [...arr]);
        }
    }, [props, selectedMeals]);

    const onChangeOccassions = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedOccasions.includes(item.id)) {
            const idx = selectedOccasions.indexOf(item.id);
            const arr = props.fields.occasions;

            arr.splice(idx, 1);
            props.setValue('occasions', [...arr]);
        } else if (selectedOccasions.length + selectedFeelings.length < 4) {
            const arr = props.fields.occasions;

            arr.push(item);
            props.setValue('occasions', [...arr]);
        }
    }, [props, selectedFeelings.length, selectedOccasions]);

    const onChangeFeelings = useCallback((item) => {
        props.clearErrors('cuisines');

        if (selectedFeelings.includes(item.id)) {
            const idx = selectedFeelings.indexOf(item.id);
            const arr = props.fields.feelings;

            arr.splice(idx, 1);
            props.setValue('feelings', [...arr]);
        } else if (selectedOccasions.length + selectedFeelings.length < 4) {
            const arr = props.fields.feelings;

            arr.push(item);
            props.setValue('feelings', [...arr]);
        }
    }, [props, selectedFeelings, selectedOccasions.length]);

    const onChangeInstruction = useCallback((value, index) => {
        const arr = props.fields.instructions;

        arr[index] = value;

        props.setValue('instructions', arr);
    }, [props]);

    const addStepHandler = useCallback(() => {
        props.setValue('instructions', [...props.fields.instructions, '']);
    }, [props]);

    const removeStepHandler = useCallback((index) => {
        const arr = props.fields.instructions;

        arr.splice(index, 1);

        props.setValue('instructions', [...arr]);
    }, [props]);

    const renderInstruction = useCallback((instruction, index) => {
        return (
            <>
                <Form.Label key={index} className='form-label mt-3'>Step {index + 1}</Form.Label>
                <div className='input-unit-wrapper'>
                    <input value={instruction} onChange={(e) => onChangeInstruction(e.target.value, index)}
                        type='text' placeholder='Enter Step' />
                    <div onClick={() => removeStepHandler(index)} className={`hoverable ${props.fields?.instructions?.length === 1 ? 'input-unit-wrapper-disabled' : ''}`}>Delete</div>
                </div>
            </>
        );
    }, [onChangeInstruction, props.fields?.instructions, removeStepHandler]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='recipeName'>
                    <div className='form-label-wrapper'>
                        <Form.Label className='form-label-section'>Steps</Form.Label>
                        <div onClick={addStepHandler} className='form-label-red hoverable'>+ Add Step</div>
                    </div>
                    {props.fields?.instructions?.map(renderInstruction)}
                    {props.errors?.instructions?.message && <Form.Text className='form-error-message'>{props.errors?.instructions?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Common Ingredients</Form.Label>
                    {props.errors?.cuisines?.message && <Form.Text className='form-error-message'>{props.errors?.cuisines?.message}</Form.Text>}
                    <div className='form-checkbox-wrapper'>
                        {commonIngredientsData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeCommonIngredients(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedCommonIngredients.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Cuisine</Form.Label>
                    <div className='form-checkbox-wrapper'>
                        {cuisineData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeCuisines(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedCuisine.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Equipment</Form.Label>
                    <div className='form-checkbox-wrapper'>
                        {equipmentData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeEqupments(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedEquiments.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Type</Form.Label>
                    <div className='form-checkbox-wrapper'>
                        {mealsData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeMeals(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedMeals.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Occasion</Form.Label>
                    <div className='form-checkbox-wrapper'>
                        {occasionData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeOccassions(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedOccasions.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Feeling</Form.Label>
                    <div className='form-checkbox-wrapper'>
                        {feelingData.data.map((item, index) => {
                            return (
                                <div onClick={() => onChangeFeelings(item)} key={index} className='form-radio hoverable'>
                                    <div className={`form-checkbox ${selectedFeelings.includes(item.id) ? 'form-checkbox-active' : ''}`} />
                                    <div className='form-radio-label'>{item.name}</div>
                                </div>
                            );
                        })}
                    </div>
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button onClick={props.showPreview} variant='brand-red-hollow' ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={props.onBack}>Back</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Next</Button>
                </div>
            </div>
        </div>
    );
}

DataForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired
};

export default memo(DataForm);
