import { AdminApi } from './api';

/**
 * Get searched recipe
 * @param {String} query
 * @param {Number} page
 * @param {Number} perPage
 * @returns {Promise}
 */
export function searchRecipe(query, page, perPage = 15) {
    return AdminApi.get('/v1/recipes/search', { params: { query, page, perPage } });
}

/**
 * Delete by ids
 * @param {Array} ids
 * @returns {Promise}
 */
export function recipesDelete(ids) {
    return AdminApi.post('/v1/recipes/delete', { ids });
}

/**
 * Get One By Id
 * @param {Number} id
 * @returns {Promise}
 */
export function getById(id) {
    return AdminApi.get(`/v1/recipes/${id}`);
}

/**
 * Create Recipe
 * @param {Object} data
 * @returns {Promise}
 */
export function createRecipe(data) {
    return AdminApi.post('/v1/recipes/', data);
}

/**
 * Create Recipe
 * @param {Number} id
 * @param {Object} data
 * @returns {Promise}
 */
export function updateRecipe(id, data) {
    return AdminApi.post(`/v1/recipes/update/${id}`, data);
}
