import { searchChallenge } from 'api/challenge';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';

import AddIngredients from './AddIngredients';

function DataForm(props) {
    const [challengeData, setChallengeData] = useState({ page: 1, isLoading: false, data: [] });
    const [showList, setShowList] = useState(false);

    const fetchChallenges = useCallback(async (query) => {
        try {
            setChallengeData({ ...challengeData, isLoading: true });

            const { data, totalPages } = await searchChallenge(query, 1, 15);

            setChallengeData({ currentPage: data.current_page, lastPage: totalPages, isLoading: false, data: [...data] });
        } catch {
            setChallengeData({ ...challengeData, isLoading: false });
        }
    }, [challengeData]);

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            fetchChallenges(props.fields?.challenge?.hashtag);
        }, 500);

        return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.fields?.challenge?.hashtag]);

    const renderChallenge = useCallback(() => {
        if (challengeData.data.length === 0) {
            return <span className='challenge-type'>Choose Challenge Category</span>;
        }

        return challengeData.data.map((challenge) => {
            return (
                <Dropdown.Item key={challenge.id} onClick={() => props.setValue('challenge', challenge)}>{challenge.hashtag}</Dropdown.Item>
            );
        });
    }, [challengeData.data, props]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Preparation Time</Form.Label>
                    <div className='input-unit-wrapper'>
                        <input value={props.fields?.preptime} onChange={(e) => props.setValue('preptime', e.target.value)}
                            type='number' min={0} placeholder='Enter Preparation Time' />
                        <div>Mins</div>
                    </div>
                    {props.errors?.preptime?.message && <Form.Text className='form-error-message'>{props.errors?.preptime?.message}</Form.Text>}
                </Form.Group>
                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Cooking Time</Form.Label>
                    <div className='input-unit-wrapper'>
                        <input value={props.fields?.cookingtime} onChange={(e) => props.setValue('cookingtime', e.target.value)}
                            type='number' min={0} placeholder='Enter Cooking Time' />
                        <div>Mins</div>
                    </div>
                    {props.errors?.cookingtime?.message && <Form.Text className='form-error-message'>{props.errors?.cookingtime?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeHashtag'>
                    <Form.Label className='form-label'>Challenge Tag <span className='form-label-count'>(Optional)</span></Form.Label>
                    <Dropdown onToggle={setShowList}>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>
                            <input value={props.fields?.challenge?.hashtag} onChange={(e) => props.setValue('challenge', { ...props.fields?.challenge, hashtag: e.target.value })}
                                className='organizer-input'
                                type='text'
                                placeholder='Choose Challenge Tag' />
                        </Dropdown.Toggle>
                        <Dropdown.Menu show={showList}>
                            {renderChallenge()}
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <AddIngredients ingredients={props.fields?.ingredients} setValue={props.setValue} error={props.errors?.ingredients?.message} />
            </Form>
            <div className='form-footer'>
                <Button onClick={props.showPreview} variant='brand-red-hollow' ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={props.onBack}>Back</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Next</Button>
                </div>
            </div>
        </div>
    );
}

DataForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired
};

export default memo(DataForm);
