import { getById } from 'api/recipe';
import VideoPlayer from 'components/VideoPlayer/VideoPlayer';
import { saveAs } from 'file-saver';
import Profile from 'images/Profile.svg';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { VscCloudDownload } from 'react-icons/vsc';
import { Link, useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getHourMinute } from 'utils/helpers';
import { getRecipeImages, getRecipePublisherImage } from 'utils/recipeHelpers';

async function downloadImage(imageSrc, name) {
    try {
        const image = await fetch(imageSrc);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement('a');

        link.href = imageURL;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (err) {
        saveAs(imageSrc, name);
    }
}

function Recipe() {
    const { id } = useParams();
    const [data, setData] = useState(null);
    const [groupedIngredients, setGroupedIngredients] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const history = useHistory();
    const { publisherImage, recipeImages } = useMemo(() => {
        return {
            publisherImage: getRecipePublisherImage(data),
            recipeImages: getRecipeImages(data?.is_uploaded, data?.image_name, data?.images)
        };
    }, [data]);

    useEffect(() => {
        const groupedIngredients = {};

        data?.ingredients?.forEach(ingredient => {
            const section = ingredient?.pivot?.section || '';

            if (groupedIngredients[section]) {
                groupedIngredients[section] = [...groupedIngredients[section], ingredient];
            } else {
                groupedIngredients[section] = [ingredient];
            }
        });

        setGroupedIngredients(groupedIngredients);
    }, [data?.ingredients]);

    const dietaries = useMemo(() => {
        return data?.dietaries?.map((dietary) => dietary.name)?.join(', ');
    }, [data]);

    const cuisine = useMemo(() => {
        return data?.cuisines?.map((data) => data.name)?.join(', ');
    }, [data]);

    const commonIngredients = useMemo(() => {
        return data?.common_ingredients?.map((data) => data.name)?.join(', ');
    }, [data]);

    const equipment = useMemo(() => {
        return data?.equipment?.map((data) => data.name)?.join(', ');
    }, [data]);

    const meals = useMemo(() => {
        return data?.meals?.map((data) => data.name)?.join(', ');
    }, [data]);

    const occasion = useMemo(() => {
        return data?.occasions?.map((data) => data.name)?.join(', ');
    }, [data]);

    const feeling = useMemo(() => {
        return data?.feelings?.map((data) => data.name)?.join(', ');
    }, [data]);

    const fetchData = useCallback(async () => {
        try {
            setIsLoading(true);

            const response = await getById(id);

            if (response) {
                setData(response);
            }
        } catch {
            toast.error('Unable to fetch Recipe', {
                position: toast.POSITION.BOTTOM_LEFT
            });
        } finally {
            setIsLoading(false);
        }
    }, [id]);

    useEffect(() => {
        fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderDifficylty = (difficulty) => {
        switch (difficulty) {
            case '1':
                return 'Easy';
            case '2':
                return 'Medium';
            case '3':
                return 'Hard';
            default:
                return 'Unknown';
        }
    };

    const renderImage = (img, index) => {
        const onDownload = () => {
            downloadImage(img, `${data.url_slug}-image-${index + 1}.jpg`);
        };

        return (
            <div className='position-relative' key={index}>
                <img src={img} alt='recipe media' />
                <div onClick={onDownload} className='download-icon-small' ><VscCloudDownload size={22} color='#FF7268' /></div>
            </div>
        );
    };

    return (
        <>
            <Helmet>
                <title>{`${data?.recipename} - Admin YoRipe`}</title>
                <meta name='title' content={`${data?.recipename} - Admin YoRipe`} />
                <meta name='description' content={'Recipe Form page to manage all existing recipes and create more!'} />
            </Helmet>
            <div className='page-wrapper'>
                <div className='challenge-wrapper'>
                    <div className='challenge-header'>View Recipe</div>
                    {isLoading && <div className='challenge-header'><Spinner animation='border' variant='red-1' /></div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe ID</div>
                        <div className='challenge-section-content'>{data?.id}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Recipe Name</div>
                        <div className='challenge-section-content'>{data?.recipename}</div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Image</div>
                        <div className='image-grid-container'>{recipeImages?.map(renderImage)}</div>
                    </div>
                    {data?.video && <div className='challenge-section'>
                        <div className='challenge-section-title'>Video</div>
                        <div className='challenge-section-video'><VideoPlayer videoUrl={data?.video} thumbnail={data?.video_thumbnail} downloadName={data?.url_slug} /></div>
                    </div>}
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Publisher</div>
                        <div className='challenge-section-content'>
                            <img className='recipe-publisher-image' src={publisherImage ?? Profile} alt={data?.publisher?.name ?? 'YoRipe'} />
                            {data?.publisher?.name ?? 'YoRipe'}
                        </div>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Description</div>
                        <p className='challenge-section-content'>{data?.description}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Servings</div>
                        <p className='challenge-section-content'>{data?.servings}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Language</div>
                        <p className='challenge-section-content'>{!data?.language && 'All'}{data?.language === 'en' && 'English'}{data?.language === 'id' && 'Indo'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Price Range</div>
                        <p className='challenge-section-content'>{data?.price === '1' && '$1 - $50'}{data?.price === '2' && '$50 - $100'}{data?.price === '3' && '$100 - $150'}{!data?.price && 'null'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Difficulty</div>
                        <p className='challenge-section-content'>{renderDifficylty(data?.level_of_difficulty)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Dietary Tags</div>
                        <p className='challenge-section-content'>{data?.dietaries.length > 0 ? dietaries : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Prepration Time</div>
                        <p className='challenge-section-content'>{getHourMinute(data?.preptime)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Cooking Time</div>
                        <p className='challenge-section-content'>{getHourMinute(data?.cookingtime)}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Challenge Tag</div>
                        {data?.challenges
                            ? <Link to={`/challenge/${data?.challenges?.id}`} className='challenge-section-content'>{data?.challenges?.hashtag}</Link>
                            : <p className='challenge-section-content'>None</p>}
                    </div>
                    <div className='data-section-heading'>Ingredients</div>
                    {Object.keys(groupedIngredients).map((key, index) => (
                        <React.Fragment key={index}>
                            {key !== '' && (
                                <div className='ingredient-section-title'>{key}</div>
                            )}
                            {groupedIngredients[key].map((ingredient, index) => {
                                return (
                                    <div className='challenge-section' key={index}>
                                        <div className='challenge-section-title'>{ingredient.name}</div>
                                        <p className='challenge-section-content'>{ingredient?.pivot?.quantity} {ingredient?.pivot?.serving} {ingredient?.pivot?.comment && <span className='challenge-section-comment'>({ingredient?.pivot?.comment})</span>}</p>
                                    </div>
                                );
                            })}
                        </React.Fragment>
                    ))}
                    <div className='data-section-heading'>Steps</div>
                    {data?.instructions.map((instruction, index) => {
                        return (
                            <div className='challenge-section' key={index}>
                                <div className='challenge-section-title'>Step {index + 1}</div>
                                <p className='challenge-section-content'>{instruction}</p>
                            </div>
                        );
                    })}
                    <div className='data-section-heading'>Category</div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Common Ingredients</div>
                        <p className='challenge-section-content'>{data?.common_ingredients?.length > 0 ? commonIngredients : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Cuisine</div>
                        <p className='challenge-section-content'>{data?.cuisines?.length > 0 ? cuisine : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Equipment</div>
                        <p className='challenge-section-content'>{data?.equipment?.length > 0 ? equipment : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Meals</div>
                        <p className='challenge-section-content'>{data?.meals?.length > 0 ? meals : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Occasion</div>
                        <p className='challenge-section-content'>{data?.occasions?.length > 0 ? occasion : 'None'}</p>
                    </div>
                    <div className='challenge-section'>
                        <div className='challenge-section-title'>Feeling</div>
                        <p className='challenge-section-content'>{data?.feelings?.length > 0 ? feeling : 'None'}</p>
                    </div>
                    <div className='challenge-footer'>
                        <Link to={`/recipe-edit/${data?.id}`}><Button variant='brand-red mx-2'>Edit Recipe</Button></Link>
                        <Button variant='brand-gray mx-2' onClick={history.goBack}>Back</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(Recipe);
