import { uploadImage } from 'api/upload';
import ConfirmPopup from 'components/ConfirmPopup';
import Media from 'components/Media';
import PropTypes from 'prop-types';
import React, { memo, useCallback, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { AiFillEye } from 'react-icons/ai';
import { BsFillImageFill } from 'react-icons/bs';
import Resizer from 'react-image-file-resizer';
import { useHistory } from 'react-router-dom';

function DataForm(props) {
    const recipeImageRef = useRef();
    const thumbnailRef = useRef();
    // const recipeVideoRef = useRef();
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setUploading] = useState(false);
    const history = useHistory();

    const uploadImageHandler = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImage(image);

            props.setValue('images', [...props.fields.images, uploadResponse]);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const recipeImageHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadImageHandler(uri));
            } catch (err) {
            }
        }
    }, [uploadImageHandler]);

    const uploadThumbnailHandler = useCallback(async (image) => {
        try {
            setUploading(true);

            const uploadResponse = await uploadImage(image);

            props.setValue('video_thumbnail', uploadResponse);
        } finally {
            setUploading(false);
        }
    }, [props]);

    const recipeThumbnailHandler = useCallback(async (event) => {
        if (event.target.files[0]) {
            try {
                Resizer.imageFileResizer(event.target.files[0], 2048, 2048, 'PNG', 85, 360, (uri) => uploadThumbnailHandler(uri));
            } catch (err) {
            }
        }
    }, [uploadThumbnailHandler]);

    const removeIndexHandler = (index) => {
        const arr = props.fields?.images;

        arr.splice(index, 1);

        props.setValue('images', arr);
    };

    const renderLanguage = useCallback(() => {
        if (props.fields?.language === 'en') {
            return <div className='challenge-type selected-type-text'>English</div>;
        } else if (props.fields?.language === 'id') {
            return <div className='challenge-type selected-type-text'>Indo</div>;
        } else {
            return <div className='challenge-type selected-type-text'>All</div>;
        }
    }, [props.fields?.language]);

    return (
        <div className='form-wrapper'>
            <Form>
                <Form.Group className='mb-3' controlId='recipeName'>
                    <Form.Label className='form-label'>Recipe Name</Form.Label>
                    <input value={props.fields?.recipename} onChange={(e) => props.setValue('recipename', e.target.value)}
                        className='form-input' type='text' placeholder='Enter Recipe Name' />
                    {props.errors?.recipename?.message && <Form.Text className='form-error-message'>{props.errors?.recipename?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeImages'>
                    <Form.Label className='form-label'>Images</Form.Label>
                    {props.fields?.images.map((image, index) => <Media onClose={() => removeIndexHandler(index)} src={image} key={index}
                        alt='recipe images'
                        className='form-input-image' />)}
                    <div onClick={() => recipeImageRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                        <span>Upload imgae.</span>
                    </div>
                    <input type='file' multiple={false} ref={recipeImageRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={recipeImageHandler} />
                    {props.errors?.images?.message && <Form.Text className='form-error-message'>{props.errors?.images?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeImages'>
                    <Form.Label className='form-label'>Video Thumbnail</Form.Label>
                    {props.fields?.video_thumbnail && <Media onClose={() => props.setValue('video_thumbnail', '')} src={props.fields?.video_thumbnail}
                        alt='video thumbnail'
                        className='form-input-image' />}
                    {!props.fields?.video_thumbnail && <div onClick={() => thumbnailRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                        <span>Upload thumbnail.</span>
                    </div>}
                    <input type='file' multiple={false} ref={thumbnailRef}
                        accept='image/*'
                        style={{ display: 'none' }}
                        onChange={recipeThumbnailHandler} />
                    {props.errors?.video_thumbnail?.message && <Form.Text className='form-error-message'>{props.errors?.video_thumbnail?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeImages'>
                    <Form.Label className='form-label'>Video</Form.Label>
                    {props.fields?.video && <div className='challenge-section-video'><video className='recipe-video' controls={true} src={props.fields?.video}/></div>}
                </Form.Group>

                {/* <Form.Group className='mb-3' controlId='recipeVideos'>
                    <Form.Label className='form-label'>Video</Form.Label>
                    {props.fields?.video && <video src={props.fields?.video} alt='organizer' className='form-input-video' />}
                    <div onClick={() => recipeVideoRef.current.click()} className={`image-placeholder-wrapper hoverable ${isUploading ? 'disabled' : ''}`}>
                        <BsFillImageFill color='#bdbdbd' size={40} />
                        <span>Upload video.</span>
                    </div>
                    <input type='file' multiple={true} ref={recipeVideoRef}
                        accept='video/*'
                        style={{ display: 'none' }}
                        onChange={recipeImageHandler} />
                </Form.Group> */}

                <Form.Group className='mb-3' controlId='recipeDescription'>
                    <Form.Label className='form-label'>Recipe Description</Form.Label>
                    <textarea value={props.fields?.description} onChange={(e) => props.setValue('description', e.target.value)}
                        rows={2} type='text'
                        className='form-textarea'
                        placeholder='Write Short Description' />
                    {props.errors?.description?.message && <Form.Text className='form-error-message'>{props.errors?.description?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeServings'>
                    <Form.Label className='form-label'>Portion Amount</Form.Label>
                    <div className='input-unit-wrapper'>
                        <input value={props.fields?.servings} onChange={(e) => props.setValue('servings', e.target.value)}
                            type='number' placeholder='Enter Number of Servings' />
                        <div>Servings</div>
                    </div>
                    <Form.Text className='form-error-message'>{props.errors?.servings?.message}</Form.Text>
                </Form.Group>

                <Form.Group className='mb-3' controlId='challengeType'>
                    <Form.Label className='form-label'>Language</Form.Label>
                    <Dropdown>
                        <Dropdown.Toggle className='categories-wrapper' id='dropdown-basic'>{renderLanguage()}</Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => props.setValue('language', null)}><span className={!props.fields?.language ? 'color-brand-imp' : ''}>All</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'en')}><span className={props.fields?.language === 'en' ? 'color-brand-imp' : ''}>English</span></Dropdown.Item>
                            <Dropdown.Item onClick={() => props.setValue('language', 'id')}><span className={props.fields?.language === 'id' ? 'color-brand-imp' : ''}>Indo</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipePrice'>
                    <Form.Label className='form-label'>Price Range <span className='form-label-count'>(Optional)</span></Form.Label>
                    <div onClick={() => props.setValue('price', props.fields?.price === 1 ? null : 1)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.price === 1 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>$1 - $50</div>
                    </div>
                    <div onClick={() => props.setValue('price', props.fields?.price === 2 ? null : 2)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.price === 2 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>$50 - $100</div>
                    </div>
                    <div onClick={() => props.setValue('price', props.fields?.price === 3 ? null : 3)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.price === 3 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>$100 - $150</div>
                    </div>
                    {props.errors?.price?.message && <Form.Text className='form-error-message'>{props.errors?.price?.message}</Form.Text>}
                </Form.Group>

                <Form.Group className='mb-3' controlId='recipeDifficulty'>
                    <Form.Label className='form-label'>Difficulty</Form.Label>
                    <div onClick={() => props.setValue('level_of_difficulty', 1)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.level_of_difficulty === 1 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>Easy</div>
                    </div>
                    <div onClick={() => props.setValue('level_of_difficulty', 2)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.level_of_difficulty === 2 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>Medium</div>
                    </div>
                    <div onClick={() => props.setValue('level_of_difficulty', 3)} className='form-radio hoverable'>
                        <div className={`form-radio-select ${props.fields?.level_of_difficulty === 3 ? 'form-radio-active' : ''}`} />
                        <div className='form-radio-label'>Hard</div>
                    </div>
                    {props.errors?.level_of_difficulty?.message && <Form.Text className='form-error-message'>{props.errors?.level_of_difficulty?.message}</Form.Text>}
                </Form.Group>
            </Form>
            <div className='form-footer'>
                <Button onClick={props.showPreview} variant='brand-red-hollow' ><AiFillEye color='#FF7268' />  Preview</Button>
                <div>
                    <Button variant='brand-gray mx-2' onClick={() => setShowModal(true)}>Cancel</Button>
                    <Button variant='brand-red mx-2' onClick={props.saveHandler}>Next</Button>
                </div>
            </div>
            <ConfirmPopup
                isVisible={showModal}
                onClose={() => setShowModal(false)}
                confirmTitle='Leave'
                onConfirm={history.goBack}
                title='Unsaved Changes'
                description='Are you sure want to discard this changes? Changes you made may not be saved.'
                type={1} />
        </div>
    );
}

DataForm.propTypes = {
    fields: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    setValue: PropTypes.func.isRequired,
    saveHandler: PropTypes.func.isRequired,
    showPreview: PropTypes.func.isRequired
};

export default memo(DataForm);
